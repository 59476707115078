@import "../../bootstrap/css/bootstrap.min.css";
@import "elements";

html {
  scroll-padding-top: 70px;
}

body {
  padding-top: 70px;
}

.main.container {
  height: 100%;
}

.error-404 {
  padding-top: 100px;
  text-align: center;

  .glyphicon-warning-sign {
    font-size: 200px;
    color: #ff8c00;
  }
}

#user-mailing-list {
  padding: 10px;
  margin: 10px;
  margin-top: 20px;
  border: 1px solid #cacaca;
  @include rounded(5px);
}

pre {
  border: 1px solid #ccc;
}

#side-toc {
  padding-top: 65px;
}
